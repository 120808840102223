import React, { useState, useMemo } from "react";
import "./WineRating.css";

const WineRating = ({ rating, myrating,  onChange }) => {
  const [ratings, setRatings] = useState(rating || {});
  const [personalRating, setPersonalRating] = useState(myrating || 0);

  const handleRatingChange = (criterion, value) => {
    const newRatings = {
      ...ratings,
      [criterion]: value,
    };
    setRatings(newRatings);
    if (onChange) {
      onChange(newRatings);
    }
  };

  const handleStarClick = (index) => {
    setPersonalRating(index); // Mettre à jour la note personnelle
  };

  const criteria = [
    { id: "complexite", label: "Complexité" },
    { id: "intensite", label: "Intensité" },
    { id: "equilibre", label: "Équilibre" },
    { id: "longueur", label: "Longueur" },
  ];

  // Calculer la somme
  const total = useMemo(() => {
    const sum = Object.values(ratings).reduce((acc, val) => acc + val, 0);
    return sum % 1 === 0 ? sum : sum.toFixed(1); // Affiche sans décimales si entier, sinon 1 chiffre après la virgule
  }, [ratings]);

  return (
    <div className="wine-rating-container space-y-4">
      {criteria.map(({ id, label }) => (
        <div key={id} className="space-y-2">
          <div className="rating-row space-x-4">
            <label className="rating-label">{label}</label>
            <span className="rating-value">{ratings[id]}</span>
          </div>
          <div className="relative">
            <input
              type="range"
              min="0"
              max="1"
              step="0.5"
              value={ratings[id]}
              onChange={(e) =>
                handleRatingChange(id, parseFloat(e.target.value))
              }
              className="rating-slider"
            />
            <div className="center-marker" />
          </div>
        </div>
      ))}

      {/* Ligne d'évaluation totale */}
      <div className="evaluation-section">
        <div className="rating-row space-x-4">
          <label className="evaluation-label">Évaluation</label>
          <span className="rating-value score">{total}/4</span>
        </div>
      </div>

      {/* Section Ma Notation Personnelle */}
      <div className="personal-rating">
        <label className="personal-rating-label">Ma note perso</label>
        <div className="star-container">
          {[1, 2, 3, 4, 5].map((star) => (
            <span
              key={star}
              className={`star ${star <= personalRating ? "filled" : ""}`}
              onClick={() => handleStarClick(star)}
            >
              ★
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WineRating;
