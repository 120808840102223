import React, { useState, useEffect } from 'react';
import DropdownContext from './DropdownContext';

const initialDropdownState = {
  selectedOption1: '',
  selectedOption2: [],
  TextAreaContent: '',
  textAreaComment: '',
  textAreaContentOeil: '',
  intensiteNez: '',
  selectedColor: '',
  IsEditingWineName: true,
  IsEditingTextAreaContent: true,
  IsEditingTextAreaComment: true,
  date: '',
  WineName: '',
  SavedWineName: '',
  SavedTextAreaContent: '',
  acidite: 1,
  alcool: 1,
  tanins: 1,
  volume: 1, 
  longeur_en_bouche: 1,
  smileyRating: 1,
  wineRatings: {
    complexite: 0.5,
    intensite: 0.5,
    equilibre: 0.5,
    longueur: 0.5,
  },
  personalRating: 0,
};

function DropdownProvider({ children, pageId }) {
  const [dropdownState, setDropdownState] = useState(initialDropdownState);

  useEffect(() => {
    console.log("DropdownProvider mounted with pageId:", pageId); // Debugging log
    if (pageId) {
      // Retrieve existing pages from localStorage
      const savedPages = JSON.parse(localStorage.getItem("pages")) || [];
      const existingPage = savedPages.find((page) => page.id === pageId);

      if (existingPage) {
        console.log("Loading saved state:", existingPage.data); // Debugging
        setDropdownState(existingPage.data); // Load saved state if page exists
      } else {
        console.log("No saved state found, initializing new state."); // Debugging
        setDropdownState(initialDropdownState); // Initialize new page with default state
      }
    }
  }, [pageId]);

  return (
    <DropdownContext.Provider value={{ dropdownState, setDropdownState }}>
      {children}
    </DropdownContext.Provider>
  );
}

// Make sure to export DropdownProvider as a named export
export default DropdownProvider;