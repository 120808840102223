import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./Login";
import Homepage from "./HomePage";
import DropdownPage from "./DropdownPage"; // Import the DropdownPage component
import Signup from "./Signup";
import DropdownProvider from './DropdownProvider';
import UploadEmailForm from "./UploadEmailForm";
import ThanksPage from "./ThanksPage";
import DropdownLayout from './DropdownLayout';
import AccessCode from "./AccessCode";
import ProtectedRoute from "./ProtectedRoute";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);


  return (
    <DropdownProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/" element={<AccessCode setAuthenticated={setIsAuthenticated} />} />
          <Route
            path="/homepage"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Homepage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/form/:pageId"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <DropdownLayout />
              </ProtectedRoute>
            }
          >
            <Route path="" element={<DropdownPage />} />
          </Route>
          <Route
            path="/send-email"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <UploadEmailForm />
              </ProtectedRoute>
            }
          />
          <Route
            path="/thankspage"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <ThanksPage />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </DropdownProvider>
  );
};

export default App;
