// src/AccessCode.js
import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./AccessCode.css"; // Import the CSS file

const AccessCode = ({ setAuthenticated }) => {
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/api/validate-code", { code });
      if (response.data.valid) {
        setAuthenticated(true);
        navigate("/homepage");
      } else {
        setError("Invalid code");
      }
    } catch (err) {
      console.error("Erreur Axios :", err); // Log complet de l'erreur
      setError(err.response?.data?.message || "Une erreur est survenue.");
    }
  };
  return (
    <div className="access-code-container">
      <div className="access-code-image">
        <h2>Jouons le vin, dégustons ensemble </h2>
      </div>
      <div className="access-code-white-box">
        <h1>Prêts à déguster ?</h1>
        <form className="access-form" onSubmit={handleSubmit}>
          <input
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            placeholder="Entrer votre code d'accès"
          />
          <button type="submit">Valider</button>
        </form>
        {error && <p>{error}</p>}
      </div>
    </div>
  );
};

export default AccessCode;
