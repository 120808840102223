import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./HomePage.css";
import "./LoadingPage.css";
import { RiMailSendLine } from "react-icons/ri";
import { GoTrash } from "react-icons/go";
import { LuPlus } from "react-icons/lu";
import Popup from "./PopUphome";

const HomePage = () => {
  const [pages, setPages] = useState([]);
  const navigate = useNavigate();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedPage, setSelectedPage] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // Add loading state

  useEffect(() => {
    const savedPages = JSON.parse(localStorage.getItem("pages")) || [];
    setPages(savedPages);
  }, []);

  const createNewPage = () => {
    const newPage = { id: Date.now().toString(), data: {}, name: "" };
    const updatedPages = [...pages, newPage];
    localStorage.setItem("pages", JSON.stringify(updatedPages));
    setPages(updatedPages);
    navigate(`/form/${newPage.id}`);
  };

  const handleDelete = (id) => {
    setSelectedPage(id);
    setShowModal(true);
  };

  const confirmDelete = () => {
    if (selectedPage !== null) {
      const updatedPages = pages.filter((page) => page.id !== selectedPage);
      localStorage.setItem("pages", JSON.stringify(updatedPages));
      setPages(updatedPages);
      setShowModal(false);
      setSelectedPage(null);
    }
  };

  const handleSendAllPages = async (email, name) => {
    if (!email) {
      alert("Veuillez entrer votre email avant d'envoyer les données.");
      return;
    }

    if (!name) {
      alert("Veuillez entrer votre nom avant d'envoyer les données.");
      return;
    }

    setIsLoading(true); // Set loading state to true

    const savedPages = JSON.parse(localStorage.getItem("pages")) || [];

    const payload = {
      email,
      name,
      pages: savedPages.map((page) => ({
        id: page.id,
        data: {
          selectedOption1: page.data.selectedOption1,
          selectedOption2: page.data.selectedOption2,
          TextAreaContent: page.data.TextAreaContent,
          textAreaComment: page.data.textAreaComment,
          textAreaContentOeil: page.data.textAreaContentOeil,
          selectedColor: page.data.selectedColor,
          date: page.data.date,
          WineName: page.data.WineName,
          acidite: page.data.acidite,
          alcool: page.data.alcool,
          tanins: page.data.tanins,
          volume: page.data.volume,
          longeur_en_bouche: page.data.longeur_en_bouche,
          smileyRating: page.data.smileyRating,
          wineRatings: page.data.wineRatings,
          intensiteNez: page.data.intensiteNez,
          personalRating: page.data.personalRating,
        },
      })),
    };

    try {
      const response = await fetch("/api/save-pages", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        alert("Toutes les fiches ont été envoyées avec succès !");
        localStorage.removeItem("pages"); // Clear localStorage after successful send
        setPages([]); // Update state to reflect the cleared data
      } else {
        alert("Échec de l'envoi des données.");
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi des données :", error);
      alert("Une erreur est survenue lors de l'envoi des données.");
    } finally {
      setIsLoading(false); // Set loading state to false
    }
  };

  // Clear localStorage when the tab is closed
  useEffect(() => {
    const handleTabClose = () => {
      localStorage.removeItem("pages");
      setPages([]);
    };

    window.addEventListener("beforeunload", handleTabClose);
    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  return (
    <div className="homepage-container">
      <div className="featured-section">
        <h2>MES FICHES DE DÉGUSTATION</h2>
      </div>

      <div className="create-section">
        <ul>
          {pages.map((page) => (
            <li key={page.id} className="page-item">
              <Link to={`/form/${page.id}`}>
                {page.name || `Page ${page.id}`}
              </Link>
              <GoTrash onClick={() => handleDelete(page.id)} />
            </li>
          ))}
        </ul>
      </div>

      <div className="footer-homePage">
        <div className="button-group">
          <div className="btn-rect" onClick={createNewPage}>
            <LuPlus size={40} className="btn-ajouter" />
            <p>Ajouter une fiche</p>
          </div>
          <div className="btn-rect" onClick={() => setIsPopupOpen(true)}>
            <RiMailSendLine size={40} className="btn-cloturer" />
            <p>Envoyer par mail</p>
          </div>
        </div>
        {isPopupOpen && (
          <Popup
            isOpen={isPopupOpen}
            onClose={() => setIsPopupOpen(false)}
            handleSendAllPages={handleSendAllPages}
          />
        )}
      </div>

      {/* MODAL DE CONFIRMATION */}
      {showModal && (
        <>
          <div
            className="modal-overlay"
            onClick={() => setShowModal(false)}
          ></div>
          <div className="modal">
            <p>Voulez-vous vraiment supprimer cette fiche ?</p>
            <button className="confirm" onClick={confirmDelete}>
              Oui
            </button>
            <button className="cancel" onClick={() => setShowModal(false)}>
              Annuler
            </button>
          </div>
        </>
      )}

      {/* LOADING ICON */}
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
        </div>
      )}
    </div>
  );
};

export default HomePage;
